
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import { imageShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    nextBg: string(),
    heading: oneOfType([string(), nullable()]),
    customers: arrayOf(
      shape({
        id: string(),
        logo: arrayOf(shape(imageShape).loose),
      }).loose
    ),
  },
};
